import { Fragment, useState, useEffect } from "react";
import PropTypes, { object } from "prop-types";
import { EffectFade, Thumbs } from 'swiper';
import { Modal, Form, DropdownButton, Dropdown, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setProducts, setProductsFull } from "../../store/slices/product-slice";
import { setModalidadSeleccionada } from "../../store/slices/modalidad-slice";
import cogoToast from "cogo-toast";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { getMedicamentos } from "../../firebase/firebase-methods";
import arrModalidad from "../../store/dataModalidad";

function ModalidadModal({ showModalidad, onHideModalidad }) {
    const dispatch = useDispatch();
    //const { arrModalidad } = useSelector((state) => state.modalidad);

    const [regiones, setRegiones] = useState([]);
    const [regionSeleccionada, setRegionSeleccionada] = useState('');
    const [dropDownComuna, setDropDownComuna] = useState([]);
    const [comunaSeleccionada, setComunaSeleccionada] = useState('');
    const [tipoSeleccionado, setTipoSeleccionado] = useState('');
    const [dropDownTipo, setDropDownTipo] = useState([]);
    const [dropDownModalidad, setDropDownModalidad] = useState([]);

    const { modalidadSeleccionada } = useSelector((state) => state.modalidad);
    //console.log("se obtiene arreglo modalidad");
    //console.log([arrModalidad])
    const onCloseModal = () => {
        onHideModalidad()
    }

    const handleSeleccionaModalidad = (modalidad) => {
        if (modalidad.id === modalidadSeleccionada.id) {
            cogoToast.info("La modalidad " + modalidad.nombre + " ya está seleccionada", { position: "bottom-left" })
        } else {
            dispatch(setModalidadSeleccionada(modalidad))
            //onCloseModal()
        }
        onCloseModal()

    }
    const [modalidadSelec, setModalidadSelec] = useState(modalidadSeleccionada)
    useEffect(() => {
        setModalidadSelec(modalidadSeleccionada)
        const getProducts = async () => {
            const response = await getMedicamentos(modalidadSeleccionada.presku)
            dispatch(setProducts(response));
            dispatch(setProductsFull(response))
        }
        getProducts()


        return;
    }, [modalidadSeleccionada])

    useEffect(() => {

        //nueva forma de mostrar las modalidades
        console.log(arrModalidad);
        let arr = []
        arrModalidad.forEach(item => {
            if (!arr.includes(item.region)) {
                arr.push(item.region)
            }
        })
        console.log(arr);
        setRegionSeleccionada('')
        setRegiones(arr)

        return;
    }, []);

    useEffect(() => {
        if (showModalidad) {
            setRegionSeleccionada("")
            setComunaSeleccionada("")
            setModalidadSelec("")
        }

        return;
    }, [showModalidad]);


    useEffect(() => {
        console.log(regionSeleccionada);
        setComunaSeleccionada('')
        setModalidadSelec('')
        let arr = arrModalidad.filter(item => item.region === regionSeleccionada)
        let arrTipo = []
        arr.forEach(item => {
            if (!arrTipo.includes(item.tipo)) {
                arrTipo.push(item.tipo)
            }
        })
        console.log(arrTipo);
        setDropDownTipo(arrTipo)
        setTipoSeleccionado()

        // if (regionSeleccionada) {
        //     if (regionSeleccionada === 'Región Metropolitana') {
        //         let arrMod = arrModalidad.filter(item => item.region === regionSeleccionada)
        //         let arrComunas = []
        //         arrMod.forEach(item => {
        //             if(!arrComunas.includes(item.comuna)){
        //                 arrComunas.push(item.comuna)
        //             }
        //         })
        //         setDropDownComuna(arrComunas)
        //     }
        //     else {
        //         let arr = arrModalidad.filter(item => item.region === regionSeleccionada)
        //         setDropDownModalidad(arr)
        //     }

        // }
        // if (modalidadSeleccionada && modalidadSelec.region !== regionSeleccionada) {
        //     setModalidadSelec({})
        // }
        return;
    }, [regionSeleccionada]);

    useEffect(() => {
        console.log(tipoSeleccionado);
        //setModalidadSelec('')
        let arr = arrModalidad.filter(item => item.tipo === tipoSeleccionado && item.region === regionSeleccionada)
        setDropDownModalidad(arr)
        return;
    }, [tipoSeleccionado]);

    return (
        <Modal show={showModalidad}
            onHide={onCloseModal}
            className="modal-modalidad"
        >
            <Modal.Header closeButton><h5>Seleccione modalidad de compra</h5></Modal.Header>
            <Modal.Body className="p-20">
                {/* <div
                    className="dropdown-custom-modalidad seleccion"
                >
                    <label
                        className="ps-3 pb-2 fw-bold"
                    >
                        {"Región"}</label>
                    <DropdownButton

                        title={regionSeleccionada ? regionSeleccionada : 'Seleccione región'}
                    >
                        {
                            regiones.map((region, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => setRegionSeleccionada(region)}
                                    className="w-100"
                                >
                                    {region}
                                </Dropdown.Item>
                            ))
                        }
                    </DropdownButton>



                    <label
                        className="ps-3 pb-2 pt-3 fw-bold"
                    >
                        {"Modalidad"}
                    </label>
                    <DropdownButton

                        title={tipoSeleccionado ? tipoSeleccionado === 'despacho' ? 'Despacho a domicilio' : 'Retiro en tienda' : 'Seleccione modalidad'}
                    >
                        {
                            dropDownTipo.map((tipo, index) => (
                                <Dropdown.Item
                                    key={index}
                                    onClick={() => {
                                        if (tipo === "despacho") {
                                            setTipoSeleccionado(tipo)
                                            console.log(tipo);
                                            console.log(regionSeleccionada);
                                            let arr = arrModalidad.filter(item => item.region === regionSeleccionada && item.tipo === tipo)
                                            console.log(arr[0]);
                                            if (arr.length > 0) {
                                                console.log("seteo modalidad");
                                                setModalidadSelec(arr[0])
                                            }
                                        }
                                        else {
                                            setTipoSeleccionado(tipo)
                                            setModalidadSelec("")
                                        }

                                    }}
                                    className="w-100"
                                >
                                    {tipo === 'despacho' ? 'Despacho a domicilio' : 'Retiro en tienda'}
                                </Dropdown.Item>
                            ))
                        }
                    </DropdownButton>
                    {
                        tipoSeleccionado === 'retiro' ?
                            <div>
                                <label
                                    className="ps-3 pb-2 pt-3 fw-bold"
                                >
                                    {"Sucursal"}
                                </label>
                                <DropdownButton

                                    title={modalidadSelec.nombre ? modalidadSelec.nombre : 'Seleccione modalidad'}
                                >
                                    {
                                        dropDownModalidad.map((modalidad, index) => (
                                            <Dropdown.Item
                                                key={index}
                                                onClick={() => setModalidadSelec(modalidad)}
                                                className="w-100"
                                            >
                                                {modalidad.nombre}
                                            </Dropdown.Item>
                                        ))
                                    }
                                </DropdownButton>
                            </div>
                            :

                            null
                    }
                    {modalidadSelec.nombre ?



                        <div className="pt-3 d-block">
                            <label className="pb-1">
                                {"Modalidad de compra"}
                            </label>

                            <p className="fw-bold fs-7">
                                {modalidadSelec.nombre}
                            </p>
                            <label className="pb-1">
                                {modalidadSelec.tipo==="despacho" ? "Se despacha desde:" : "Se retira en:"}
                            </label>

                            <p className="fw-bold fs-7">
                                {modalidadSelec.direccion}
                            </p>

                            <label className="pb-1">
                                {"Horario"}
                            </label>

                            <p className="fw-bold fs-7">
                                {modalidadSelec.horario}
                            </p>



                            <p className="pt-1">
                                <label>
                                    {"Teléfono: "}
                                </label>
                                <span className="fw-bold fs-7">{modalidadSelec.telefono}</span>

                            </p>
                            {
                                modalidadSelec.tipo==="despacho" ? 
                                <div>

                            <p className="pt-1">
                                <label>
                                    {"Costo de despacho: "}
                                </label>

                                <span className="fw-bold fs-7">
                                    {"  $"}{modalidadSelec.costo ? modalidadSelec.costo.toLocaleString("es-CL") : 0}
                                </span>
                            </p>
                            </div>
                            : null
                            }
                            

                            <p className="pt-2">
                                {modalidadSelec.texto_descriptivo}
                            </p>

                        </div>
                        : null
                    }
                    <div className="pt-3 d-flex w-100 justify-content-end">

                        <Button
                        className="boton"
                        onClick={()=> handleSeleccionaModalidad(modalidadSelec)}
                        >
                            Seleccionar</Button>
                    </div>


                </div> */}

                <div className="">


                    <table>

                        <tbody>
                            {arrModalidad.map((modalidad) => (
                                <tr key={modalidad.id}>
                                    <td className=" py-3">
                                        <p className=" titulo">{modalidad.nombre}</p>
                                        <p><span>{modalidad.tipo==="despacho" ? "Se despacha desde: " : "Se retira en: "}</span></p>
                                        <p>{modalidad.direccion}</p>

                                        <p><span>{"Horario: "}</span></p>
                                        <p>{modalidad.horario}</p>
                                        <p><span>{"Teléfono: "}</span></p>
                                        <p>{modalidad.telefono}</p>
                                    </td>
                                    <td className=" py-3">


                                        <button className={modalidad.id === modalidadSeleccionada.id ? "check-modalidad-true" : "check-modalidad-false"}
                                            onClick={() => handleSeleccionaModalidad(modalidad)}
                                            style={modalidad.id === modalidadSeleccionada.id ? { textAlign: "right", color: "#fff", backgroundColor: "#e58f2f" } : { textAlign: "left", color: "darkgray", backgroundColor: "#fff" }}
                                        ><FontAwesomeIcon icon={faCircle} /></button>




                                    </td>
                                </tr>


                            ))}
                        </tbody>
                    </table>
                </div>
            </Modal.Body>


        </Modal>
    )

}

ModalidadModal.propTypes = {

    showModalidad: PropTypes.bool,
    onHideModalidad: PropTypes.func,


}

export default ModalidadModal;